@import '~leaflet/dist/leaflet.css';

.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.leaflet-container .leaflet-interactive.above {
  z-index: 1000;
}

.map-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.box-details {
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  height: calc(100vh - 40px);
  width: 400px;
  background: white;
  border-radius: 8px;
  z-index: 2000;
}

.box-details img {
  position: absolute;
  top: 11px;
  left: -35px;
  width: 52px;
  height: 52px;
}

.box-details .box-content {
  padding: 35px 35px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
}

.box-details .box-content h4 {
  font-size: 30px;
  line-height: 28px;
  font-family: 'Alegreya Sans', sans-serif;
}

.box-details .box-content div {
  line-height: 26px;
}

.box-details .box-content div b {
  margin-left: 6px;
}

.box-details .box-content p {
  line-height: 21px;
  text-align: justify;
}

.button-wrapper {
  display: flex;
  position: absolute;
  top: 0px;
  left: 50%;
  gap: 15px;
  transform: translateX(-50%);
  z-index: 2000;
}

.button-wrapper > div {
  position: relative;
}

.button-wrapper > div > ul.dropdown {
  position: absolute;
  list-style-type: none;
  background-color: white;
  color: #CC672E;
  font-size: 15px;
  border-radius: 8px;
  min-width: 225px;
  max-height: 420px;
  overflow: auto;
  left: 50%;
  top: calc(100% + 10px);
  transform: translateX(-50%);
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px 10px;
}

.button-wrapper > div > div.dropdown-pointer {
  position: absolute;
  background-color: white;
  width: 8px;
  height: 10px;
  left: 50%;
  top: calc(100%);
  transform: translateX(-50%);
}

.button-wrapper > div > ul.dropdown > li.title {
  color: #262626;
  grid-column: span 2;
}

.button-wrapper > div > ul.dropdown > li.area-opts {
  border: 1px solid #CC672E;
  border-radius: 8px;
  padding: 12px 0px;
  text-align: center;
  cursor: pointer;
  min-width: 185px;
}

.button-wrapper > div > ul.dropdown > li.area-selected {
  text-align: center;
  grid-column: span 2;
}

.button-wrapper > div > ul.dropdown > li.area-selected > span {
  color: black;
}

.button-wrapper > div > ul.dropdown > li.area-selected > svg {
  position: relative;
  top: 6px;
  margin-left: 10px;
  cursor: pointer;
}

.button-wrapper > div.button-hint {
  cursor: pointer;
}

.image-wrapper {
  position: absolute;
  top: 50%;
  left: 25px;
  width: 400px;
  transform: translateY(-50%);
  background: #262626;
  border-radius: 8px;
  z-index: 2000;
  padding: 12px;
}

.image-wrapper > .image-title {
  text-align: center;
  color: white;
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 18px;
  margin-bottom: 5px;
}

.image-wrapper > .image-content img {
  max-width: 100%;
  height: auto;
}

.image-wrapper > .image-content .nodata {
  color: grey;
  text-align: center;
  margin: 100px auto;

}

.title-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  font-family: 'Alegreya Sans', sans-serif;
  font-weight: bold;
  background-color: white;
  border-bottom-right-radius: 4px;
  padding: 10px 15px;
  font-size: 21px;
}

.hint-wrapper {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 6000;
  
}

.hint-wrapper .hint-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
}

.hint-wrapper .hint-content {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 900px;
  background: white;
  border-radius: 16px;
  padding: 40px;
  text-align: center;
  z-index: 7000;
}

.hint-wrapper .hint-title {
  font-family: 'Alegreya Sans', sans-serif;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
}

.hint-wrapper p {
  margin-bottom: 10px;
  font-size: 15px;
  text-align: justify;
}

.hint-wrapper .hint-button {
  width: 185px;
  text-align: center;
  background-color: #CC672E;
  margin: 0px auto;
  text-transform: uppercase;
  color: white;
  padding: 12px 16px;
  border-radius: 38px;
  cursor: pointer;
}

.hint-wrapper .hint-button.gray-color {
  background-color: grey;
}

.hint-wrapper .hint-contributors-images {
  position: relative;
  margin: 30px 0px 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid lightgray;
}

.hint-wrapper .hint-contributors-images img {
  margin: 0 15px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  /* top: 50%;
  left: 50%; */
  /* display: flex; */
  /* justify-self: unset; */

}

.hint-wrapper .hint-contributors-images img#ajar {
  width: 210px;
  /* height: auto; */
}

.hint-wrapper .hint-contributors-images img#lbhbandaaceh {
  width: 105px;
  /* height: auto; */
}

.hint-wrapper .hint-contributors-images img#paskaaceh {
  width: 91px;
  /* height: auto; */
}

.hint-wrapper .hint-contributors-images img#kontrasaceh {
  width: 240px;
  /* height: auto; */
}

.hint-wrapper .hint-hint .hint-hint-images {
  display: grid;
  grid-template-columns: repeat(19, 1fr);
  margin: 30px 150px 30px;
}

.hint-wrapper .hint-hint .hint-hint-images > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hint-wrapper .hint-hint .hint-hint-images .ceil {
  min-height: 64px;
}

.hint-wrapper .hint-hint .hint-hint-images .floor {
  max-width: 150px;
  justify-self: center;
}

.hint-wrapper .hint-hint .hint-hint-images .circle {
  width: 34px;
  height: 34px;
  background-color: #262626;
  border-radius: 50%;
}

.hint-wrapper .hint-button-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 400px;
  margin: 0px auto;
  
}
.ring-wrapper {
  position: relative;
}

.ring-wrapper div {
  position: absolute;
  top: 0;
  right: 0;
  border: 6px solid #533F36;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  animation: pulsate 2.4s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0
}

.ring-wrapper div.ring-1 {
  animation-delay: 0s;
}
.ring-wrapper div.ring-2 {
  animation-delay: 0.8s;
}
.ring-wrapper div.ring-3 {
  animation-delay: 1.6s;
}

@keyframes pulsate {
      0% {-webkit-transform: scale(0, 0); opacity: 1.0;}
      100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
