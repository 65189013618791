@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap');

*,
:after,
:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;
  padding: 0;
  margin: 0;
  background-color: #fff;
  overflow: hidden;
}

html, body {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

/* .show-menu {
  display: none;
  cursor: pointer;
  border: 0;
}

@media (max-width: 900px) {
  .show-menu {
    position: fixed;
    display: block;
    bottom: 30px;
    right: 0;
    padding: 10px 15px;
    background: #333;
    color: #fff;
    border-radius: 5px 0 0 5px;
    z-index: 10;
  }
} */
